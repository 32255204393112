const getDefaultState = () => {
  return {
    /* list: null,
          count: 0,
          originFilter: {}, */
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    /* totalPages: state => {
              const limit = state.originFilter?.limit || 20
              const count = state.count || 0
              return Math.ceil(count / limit)
          }, */
  },
  mutations: {
    /* resetState(state) {
              Object.assign(state, getDefaultState())
          },
          setResponse(state, data) {
              state.list = data.memberships
              state.count = data.totalCount
          },
          setOriginFilter(state, filter) {
              state.originFilter = JSON.parse(JSON.stringify(filter))
          }, */
  },
  actions: {
    // 방문객 초대 > 개인정보 수집 동의 조회
    async getVisitorCheck ({ dispatch, commit }, payload) { // 초대 내역
      let response
      try {
        response = await this._vm.get(`${this._vm.APP_API_URL}checkin/guest/${payload.guestId}/terms`)

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 방문객 초대 > 개인정보 수집 동의
    async postVisitorAgreement ({ dispatch, commit }, payload) { // 초대 내역
      let response
      try {
        response = await this._vm.post(`${this._vm.APP_API_URL}checkin/guest/${payload.guestId}/terms`, 'Y')

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 약관 조회
    async getServiceTerms (context, payload) {
      let response = null

      try {
        response = await this._vm.get(
                    `${this._vm.MEMBER_API_URL}service/terms`,
                    payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 방문객 초대 > 방문안내 정보 조회
    async getVisitorInfo (context, payload) {
      let response = null

      try {
        response = await this._vm.get(
                    `${this._vm.APP_API_URL}checkin/guest/${payload.guestId}/visit-info`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // QR 생성 / 재생성
    async getVisitorQrcode ({ dispatch, commit }, payload) { // 초대 내역
      let response
      try {
        response = await this._vm.get(`${this._vm.APP_API_URL}checkin/guest/${payload.guestId}`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // ###############

    // ID 찾기 인증 발송
    async postRecoveryFindId (context, payload) {
      let response = null
      // `${this._vm.MEMBER_API_URL}office/recovery/find/id/mobile`
      try {
        response = await this._vm.post(
                  `${this._vm.APP_API_URL}checkin/guest/{guestId}/terms`
                  , payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // ID 찾기 인증 검증하기
    async postRecoveryFindIdCheck (context, payload) {
      let response = null
      // `${this._vm.MEMBER_API_URL}office/recovery/find/id/mobile/2fa/${payload.key}`
      try {
        response = await this._vm.post(
                    `${this._vm.APP_API_URL}recovery/id/mobile/2fa/${payload.key}`
                    , payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // PASSWORD 찾기 인증 발송
    async postRecoveryFindPassword (context, payload) {
      let response = null

      try {
        response = await this._vm.post(
                    `${this._vm.APP_API_URL}recovery/password/mobile`
                    , payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // PASSWORD 찾기 인증 검증하기
    async postRecoveryFindPasswordCheck (context, payload) {
      let response = null

      try {
        response = await this._vm.post(
                      `${this._vm.APP_API_URL}recovery/password/mobile/2fa/${payload.key}`
                      , payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // PASSWORD 변경하기
    async postRecoveryPasswordRest (context, payload) {
      let response = null

      try {
        response = await this._vm.post(
                        `${this._vm.APP_API_URL}recovery/password/reset/${payload.key}`
                        , payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
