import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import PortalVue from 'portal-vue'

import isOutside from '@/directive/isOutside'
import validity from '@/directive/Validity'
import Popup from '@/components/common/Popup'
import SpSelect from '@/components/common/input/SpSelect'
import SpInput from '@/components/common/input/SpInput'
import SpButton from '@/components/common/input/SpButton'
import SpCheckbox from '@/components/common/input/SpCheckbox'
import SpTimer from '@/components/common/SpTimer'
import SpTabbedPanel from '@/components/common/SpTabbedPanel'

import './mixin/Common'
import './assets/scss/common.scss'

Vue.config.productionTip = false
Vue.config.ignoredElements = [/^ion-/] // https://ionic.io/ionicons

const API_TIMEOUT = 35000
Vue.prototype.$http = axios.create({
  timeout: API_TIMEOUT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

Vue.use(isOutside)
Vue.use(validity)
Vue.use(PortalVue)
Vue.component('Popup', Popup)
Vue.component('SpSelect', SpSelect)
Vue.component('SpInput', SpInput)
Vue.component('SpButton', SpButton)
Vue.component('SpCheckbox', SpCheckbox)
Vue.component('SpTimer', SpTimer)
Vue.component('SpTabbedPanel', SpTabbedPanel)

const v = new Vue({
  router,
  store,
  created () {
    this.$http.interceptors.request.use(
      (config) => {
        const source = axios.CancelToken.source()
        config.cancelToken = source.token
        store.commit('addCancelToken', source)
        store.commit('increaseRequestCount')
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
    this.$http.interceptors.response.use(
      this.responseSuccess,
      this.responseError,
    )
  },
  render: h => h(App),
})

router.onReady(() => {
  v.$mount('#app')
})
