import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

const Error404 = () => import('@/components/common/error/Error404')
const Error403 = () => import('@/components/common/error/Error403')
const Login = () => import('@/components/login/Login')
const RecoveryId = () => import('@/components/recovery/RecoveryId')
const RecoveryPassword = () => import('@/components/recovery/RecoveryPassword')
// const TermsOfService = () => import('@/components/signup/TermsOfService')
// const UserCreateForm = () => import('@/components/signup/UserCreateForm')
const Welcome = () => import('@/components/signup/Welcome')
const PassCertification = () => import('@/components/webview/PassCertification')
const TermsOfServiceApp = () => import('@/components/webview/TermsOfServiceApp')

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Error404',
    component: Error404,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
    },
  },
  {
    path: '*',
    name: 'Error403',
    component: Error403,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
    },
  },

  // #####아무것도 노출 안되는 빈 페이지#####
  {
    path: '*',
    name: 'ErrorNopage',
    component: () => import('@/components/common/error/ErrorNopage.vue'),
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
    },
  },

  // #####점검 안내 페이지#####
  // {
  //   path: '/error-maintenance',
  //   name: 'ErrorMaintenance',
  //   component: () => import('@/components/common/error/ErrorMaintenance.vue'),
  //   meta: {
  //     requiresAuth: false,
  //     fullScreen: true,
  //     navigation: true,
  //   },
  // },

  // ##########시작 여기는 알림톡 통해 유입된 페이지입니다.
  // 방문자 초대 메시지 통해 최초로 들어가지는 게이트 화면
  {
    path: '/invite/qr/agreement',
    name: 'GuestInviteAgreementPage',
    component: () => import('@/components/guest/GuestInviteAgreementPage.vue'),
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
    },
  },
  // 방문객 QR 페이지
  {
    path: '/invite/qr/qrcode',
    name: 'GuestInviteQrPage',
    component: () => import('@/components/guest/GuestInviteQrPage.vue'),
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
    },
  },
  // 초대 (시간만료,취소)페이지
  {
    path: '/invite/qr/error/:code',
    name: 'GuestInviteErrorPage',
    component: () => import('@/components/guest/GuestInviteErrorPage.vue'),
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
    },
  },
  // ##########끝 여기는 알림톡 통해 유입된 페이지입니다.

  {
    path: '/',
    name: 'Main',
    redirect: { name: 'Login' },
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
    },
  },

  // #####로그인#####
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
    },
    beforeEnter: (to, from, next) => {
      // 만약 쿼리값에 token값이 없다면 없는페이지로 간주해 404 로 이동
      if (!to.query.token) {
        next({ name: 'Error403', params: [to.path], replace: true })
      } else {
        next()
      }
    },
  },
  // #####로그인성공 페이지#####
  {
    path: '/login-success',
    name: 'LoginSuccess',
    component: () => import('@/components/login/LoginSuccess.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: true,
      navigation: false,
    },
  },
  // #####앱설치 유도 페이지#####
  {
    path: '/app-install',
    name: 'AppInstall',
    component: () => import('@/components/login/AppInstall.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: true,
      navigation: false,
    },
  },
  // #####회원가입 페이지#####
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/components/signup/Signup.vue'),
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
    },
  },
  // 아이디/비밀번호 찾기
  {
    path: '/recoverypage',
    name: 'Recovery',
    component: () => import('@/components/recovery/Recovery.vue'),
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },

  // {
  //   path: '/signup',
  //   redirect: '/signup/step1',
  //   meta: {
  //     requiresAuth: false,
  //     fullScreen: true,
  //     navigation: true,
  //   },
  // },
  // {
  //   path: '/signup/step1',
  //   name: 'TermsOfService',
  //   component: TermsOfService,
  //   meta: {
  //     requiresAuth: false,
  //     fullScreen: true,
  //     navigation: true,
  //   },
  // },
  // {
  //   path: '/signup/step2',
  //   name: 'UserCreateForm',
  //   component: UserCreateForm,
  //   beforeEnter: (to, from, next) => {
  //     const getCookie = (name) => {
  //       const matches = document.cookie.match(
  //         new RegExp(
  //           '(?:^|; )'
  //             + name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1')
  //             + '=([^;]*)',
  //         ),
  //       )
  //       return matches ? decodeURIComponent(matches[1]) : undefined
  //     }
  //     const impUid = getCookie('_imp_uid')
  //     if (impUid) {
  //       next()
  //     } else {
  //       next({
  //         name: 'TermsOfService',
  //         query: { redirect: to.query?.redirect },
  //       })
  //     }
  //   },
  //   meta: {
  //     requiresAuth: false,
  //     fullScreen: true,
  //     navigation: true,
  //   },
  // },
  {
    path: '/signup/welcome',
    name: 'Welcome',
    component: Welcome,
    meta: {
      requiresAuth: false,
      fullScreen: false,
      navigation: false,
    },
  },
  {
    path: '/webview/pass-certification',
    name: 'PassCertification',
    component: PassCertification,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
    },
  },
  {
    path: '/webview/terms/privacy-policy',
    name: 'TermsOfServiceApp',
    component: TermsOfServiceApp,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
      serviceType: 'officeb-platform',
      termsType: 'oms-privacy-policy',
    },
  },

  {
    path: '/webview/terms/service-use',
    name: 'ServiceUse',
    component: TermsOfServiceApp,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
      serviceType: 'officeb',
      termsType: 'oms-use-terms',
    },
  },
  {
    path: '/recovery/id',
    name: 'RecoveryId',
    component: RecoveryId,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
    },
  },
  {
    path: '/recovery/password',
    name: 'RecoveryPassword',
    component: RecoveryPassword,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
    },
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('user/logoutSparkOne')
      } catch (error) {}
      window.location.replace(decodeURIComponent(to.query.redirect))
    },
  },
  {
    path: '/verification/token/:token',
    name: 'VerificationToken',
    component: () => import('@/components/common/VerificationToken.vue'),
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

const getCookie = (name) => {
  const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
  return cookieValue ? cookieValue.pop() : ''
}

// 엑세스 토큰 보유여부 확인
const isLogined = function () {
  return !!(getCookie('member_token') || store.state.user.accessToken)
}
// 네비게이션 가드
router.beforeEach(async (to, from, next) => {
  // 진행중이던 axios 모두 취소
  store.dispatch('cancelPendingRequests')
  // 로그인이 필요한 페이지여부 확인
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // 로그인 여부 확인
    if (isLogined()) {
      next()
    } else {
      // 엑세스 토큰 없음, 리프레시 토큰 갱신 시도
      next({ name: 'Error403', params: [to.path], replace: true }) // 토큰이 없으면 403으로 보냄
      // if (await store.dispatch('user/refresh')) {
      //   // 리프레시 시도 성공
      //   // 페이지 접근 권한 확인 (구현 안 함)
      //   next()
      //   // 리프레시 실패 (토큰 없음 or 만료 or 인증서버 로직 에러)
      // } else {
      //   // 로그인 페이지로 리다이렉트
      //   next({
      //     path: '/login',
      //     query: { redirect: window.location.href },
      //   })
      // }
    }
    // 권한이 필요 없는 페이지
  } else {
    // // 리다이렉트 파라미터 유무 확인
    // let redirect = from.query?.redirect
    // const allowed = /^h?t?t?p?s?:?\/\/[a-zA-Z0-9.-]{1,}\.sparkplus\.kr(?:[/?][\w#!:.?+=&%@!-/]*)?$/
    // // 허용된 도메인 여부
    // if (!allowed.test(redirect)) {
    //   // 허용되지 않은 경우 강제 치환
    //   redirect = process.env.VUE_APP_DEFAULT_REDIRECTION
    // }
    // if (!allowed.test(to.query?.redirect)) {
    //   // 허용 안 될 시 치환 후 이동
    //   to.query.redirect = redirect
    //   next({ path: to.path, query: to.query })
    // } else {
    //   // 허용 될 시 원뵨 rediect 물고 이동
    //   next()
    // }

    next()
  }
})

export default router
