import Vue from 'vue'
import store from '../store'
import dateFormat from 'dateformat'
Vue.mixin({
  computed: {
    MEMBER_API_URL () {
      return process.env.VUE_APP_MEMBER_API_URL
    },
    APP_API_URL () {
      return process.env.VUE_APP_APP_API_URL
    },
    query () {
      const q = {
        query: {
          page: 1,
          ...this.filter,
        },
      }
      delete q.query.offset
      return q
    },
  },
  methods: {
    get (url, payload) {
      return this.request('GET', url, payload)
    },
    post (url, payload) {
      return this.request('POST', url, payload)
    },
    put (url, payload) {
      return this.request('PUT', url, payload)
    },
    delete (url, payload) {
      return this.request('DELETE', url, payload)
    },
    request (method, url, payload) {
      // GET 일 경우 payload 를 queryParameter
      if (['GET', 'DELETE'].includes(method) && payload) {
        const param = new URLSearchParams()
        for (const key in payload) {
          if (payload[key] instanceof Array) {
            for (let i = 0; i < payload[key].length; i++) {
              param.append(key, payload[key][i])
            }
          } else {
            param.append(key, payload[key])
          }
        }
        url += (url.includes('?') ? '&' : '?') + param.toString()
      }

      return new Promise((resolve, reject) => {
        let _axios = this.$http
        const config = {
          responseType: 'json',
          method,
          url,
          data: payload,
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        }

        if (store.state.user.ssoToken) {
          config.headers = {
            Authorization: 'Bearer ' + store.state.user.accessToken,
            'Content-Type': 'application/json; charset=utf-8',
          }
        }

        _axios(config)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => reject(error))
        _axios = undefined
      })
    },
    isOk (response) {
      if (!response) return false
      return !response?.response?.data?.error
    },
    getHTTPErrorStatus (response) {
      return response?.response?.data?.error?.code
    },
    alert ({ title = '스파크플러스', message = '오류가 발생했습니다.' }) {
      store.commit('addModal', {
        title,
        message,
        actionCode: 'ALERT',
      })
    },
    alertLogin ({ title = '스파크플러스', message = '다시 로그인 해 주세요.' }) {
      store.commit('addModal', {
        title,
        message,
        actionCode: 'LOGIN',
      })
    },
    _actionCodeExcute (response) {
      if (!this.isOk(response)) {
        const action = response?.response?.data?.error
        switch (action.actionCode) {
          case 'ALERT':
            this.alert(action)
            break
          case 'LOGIN':
            this.alertLogin(action)
            break
          case 'TOAST':
            action.icon = 'error'
            this.toast(action)
            break
          case 'MAINTENANCE':
            store.state.user.maintenanceDetail = action
            // this.alert(action)
            // this.$router.push(`/error-maintenance?message=${action.message}`)
            break
          case 'REDIRECT':
            // REDIRECT 인경우 응답값의 메시지 값 기준으로 페이지 이동시킴
            this.$router.push(action.message)
            break
          default:
            break
        }
      }
    },

    toast ({
      title = 'OFFiCE<sup>Ⓑ</sup>',
      message = '오류가 발생했습니다.',
      icon = 'success',
      button = {},
      duration = 2750,
      bottom = 0,
    }) {
      // return new Promise((resolve) => {
      store.commit('addToast', {
        title,
        message,
        actionCode: 'TOAST',
        icon,
        button,
        bottom,
      })
      setTimeout(() => {
        store.state.toasts.shift()
        // resolve()
      }, duration)
      // })
    },

    getMessage (response) {
      return (
        response?.response?.data?.error?.message
        || '알 수 없는 오류가 발생했습니다.\n새로고침 해주세요.'
      )
    },
    mapFilter (query) {
      let filter = {
        ...query,
      }
      if (query?.page) {
        const page = query.page || 1
        const limit = query.limit || 50
        const offset = (page - 1) * limit
        filter = {
          ...query,
          offset,
          limit,
        }
        delete filter.page
      }
      return this.deepCopy(filter)
    },
    initDate: (day) => {
      if (day <= 0) {
        return [null, null]
      }
      const dateFrom = new Date(new Date().setHours(0, 0, 0, 0))
      const dateTo = new Date(new Date().setHours(0, 0, 0, 0))
      dateTo.setDate(dateTo.getDate() + day - 1)
      return [dateFrom.toISOString(), dateTo.toISOString()]
    },
    formatGoogle (ISODateString) {
      if (ISODateString) {
        return dateFormat(ISODateString, 'yyyymmdd"T"HHMMss"+09:00"')
      }
      return ''
    },
    formatHHMM (ISODateString) {
      if (ISODateString) {
        return dateFormat(new Date(ISODateString), 'HH:MM')
      }
      return ''
    },
    formatISODate (ISODateString, defaultFormat = 'yyyy-mm-dd HH:MM:ss') {
      if (ISODateString) {
        return dateFormat(new Date(ISODateString), defaultFormat)
      }
      return ''
    },
    formatDateKo (ISODateString) {
      if (ISODateString) {
        return dateFormat(new Date(ISODateString), 'yyyy-mm-dd(ddd)')
      }
      return ''
    },
    formatYMD (ISODateString) {
      if (ISODateString) {
        return dateFormat(new Date(ISODateString), 'yyyy-mm-dd')
      }
      return ''
    },
    uuidv4 () {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c
          ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16),
      )
    },
    toURL (urlString) {
      let decoded = decodeURIComponent(urlString)
      if (decoded.startsWith('//')) {
        decoded.replace('//', 'https://')
      }
      if (
        !decoded.toLowerCase().startsWith('http://')
        && !decoded.toLowerCase().startsWith('https://')
      ) {
        decoded = 'https://' + decoded
      }
      return new URL(decoded)
    },
    responseSuccess (response) {
      store.commit('decreaseRequestCount')
      return response
    },
    async responseError (err) {
      store.commit('decreaseRequestCount')
      this._actionCodeExcute(err)
      return Promise.reject(err)
    },
  },
})
