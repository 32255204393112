<template>
  <div class="gap">
    <button
      :key="componentKey"
      class="button"
      :class="staticClass"
      ref="button"
      :id="uuid"
      v-on="lisnters"
      v-bind="attrs"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SpButton',
  inheritAttrs: false,

  props: {
    id: { type: String, default: '' },
  },
  data () {
    return {
      componentKey: '',
      uuid: null,
      staticClass: '',
      observer: null,
      allowedClasses: ['gap'],
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  mounted () {
    this.staticClass = [
      ...new Set(
        this.$el.classList
          .toString()
          .split(' ')
          .filter((e) => e !== 'gap'),
      ),
    ].join(' ')
    this.$el.setAttribute('class', 'gap')
    this.observer = new MutationObserver((mutations) => {
      for (const m of mutations) {
        const newValue = m.target.getAttribute(m.attributeName)
        this.$nextTick(() => {
          this.onClassChange(newValue, m.oldValue)
        })
      }
    })

    this.observer.observe(this.$el, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class'],
    })
  },
  methods: {
    forceRerender () {
      this.componentKey = this.uuidv4()
    },
    focus () {
      this.$refs.button.focus()
    },
    onClassChange (classAttrValue) {
      this.forceRerender()
      const classList = classAttrValue.split(' ')
      if (classList.length == 1) {
        return
      }
      this.$el.setAttribute('class', 'gap')
      this.staticClass = [
        ...new Set(classList.filter((e) => e !== 'gap')),
      ].join(' ')
    },
  },
  computed: {
    lisnters () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
  },
  beforeDestroy () {
    this.observer.disconnect()
  },
}
</script>

<style lang="scss" scoped>
.gap {
  max-width: 100%;
  position: relative;
  display: inline-block;
  min-width: 50px;
  box-sizing: border-box;
  // margin:0 4px;
}

.button {
  font-family: "Pretendard", Pretendard;
  display: block;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  background: yellow;
  box-sizing: border-box;
  cursor: pointer;
  text-indent: 0;
  // padding: 0px 6px;
  padding: 0;
  border-radius: 100px;
  border:0;
  background-color: #fff;
  color: #1e1e1e;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.2px;
  height: 100%;
  border:0;

  &.outline-none {
    border: none;
  }

  &.background-none {
    background: none;
  }

  &.primary {
    background-color: var(--primary);
    color: #fff;
  }

  &.red {
    color: #fff;
    border: 1px solid red;
    background-color: red;
  }

  &.black {
    color: #fff;
    border: 1px solid #000;
    background-color: #000;
  }

  &.gray-100 {
    background:var(--gray-100);
    color: #fff;
  }

  &.outline-black {
    color: #000;
    border: 1px solid #000;
  }

  &.big {
    height:64px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  &.small {
    padding: 0px 4px;
    line-height: 20px;
  }

  &.primary,
  &.red,
  &.black {
    &:disabled {
      color: #fff;
      background-color: #aaa !important;
      border-color: #999 !important;
      cursor: not-allowed;
    }
  }

  &:disabled {
    color:var(--disable-font);
    background: var(--disable-bg);
    cursor: not-allowed;
  }
}
</style>
