var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.modals),function(modal,i){return _c('popup',{key:i,class:_vm.customClass(modal),attrs:{"title":modal.title,"description":modal.message,"closeOnClickOutside":false}},[_c('div',{staticClass:"button-group"},[(modal.actionCode == 'ALERT')?_c('sp-button',{staticClass:"button big primary",on:{"click":function($event){return _vm.onEmit(i, { emit: 'alert', value: undefined })}}},[_vm._v("확인 ")]):_vm._e(),(modal.actionCode == 'LOGIN')?_c('sp-button',{staticClass:"button big primary",on:{"click":() => {
            _vm.modals.splice(i);
            const url = `/login${_vm.$route.query.token ? '?token='+_vm.$route.query.token : ''}`
            _vm.$router.push(url).catch((e) => {});
          }}},[_vm._v("확인 ")]):_vm._e(),(modal.actionCode == 'DISTRIBUTION_BATCH')?_c('sp-button',{staticClass:"button big primary",on:{"click":() => {
            _vm.modals.splice(i);
            _vm.$router.push('/tenant/info').catch((e) => {});
          }}},[_vm._v("확인 ")]):_vm._e(),(modal.actionCode == 'CONFIRM')?_c('sp-button',{staticClass:"button big btn-cancel",on:{"click":function($event){return _vm.onEmit(i, { emit: 'confirm', value: false })}}},[_vm._v("취소 ")]):_vm._e(),(modal.actionCode == 'CONFIRM')?_c('sp-button',{staticClass:"button big primary",on:{"click":function($event){return _vm.onEmit(i, { emit: 'confirm', value: true })}}},[_vm._v("확인 ")]):_vm._e()],1)])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }