<template>
  <div class="popup-wrap height_100vh" :class="modeClass">
    <div @click.self="close('outside')" class="bg"></div>
    <div class="popup-container" ref="popup">
      <!-- <button class="btn-close" @click.prevent.stop="close('outside')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.293 6.70726L22.0001 6.00015L20.5858 4.58594L19.8787 5.29304L14.5858 10.5859L9.29295 5.29304L8.58585 4.58594L7.17163 6.00015L7.87874 6.70726L13.1716 12.0002L7.87874 17.293L7.17163 18.0002L8.58584 19.4144L9.29295 18.7073L14.5858 13.4144L19.8787 18.7073L20.5858 19.4144L22.0001 18.0002L21.293 17.293L16.0001 12.0002L21.293 6.70726Z" fill="#1E1E1E"/>
        </svg>
      </button> -->

      <!-- 제목영역 값이 없으면 미노출 -->
      <div v-show="title" class="popup-title" >
        <span>{{ title }}</span>
        <slot name="button"></slot>
      </div>

      <!-- 팝업내용 -->
      <div class="popup-content">
        <div v-show="description" class="description" v-html="description"></div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: '', // full,bottom
    },
  },
  mounted () {
    if (this.$refs.popup.querySelector('button:last-child')) {
      this.$refs.popup.querySelector('button:last-child').focus()
    }
  },
  computed: {
    modeClass () {
      if (this.mode === 'full') {
        return 'full'
      } else if (this.mode === 'bottom') {
        return 'bottom'
      } else {
        return 'normal'
      }
    },
  },
  methods: {
    close (from) {
      if (from == 'outside' && this.closeOnClickOutside) {
        this.$emit('close')
      }
    },
  },

  watch: {
    async $route (to, from) {
      // 팝업이 노출된 상태에서 다른 페이지로 이동하거나뒤로가기 했을경우 팝업 닫기해줌
      this.$emit('close')
    },
  },

}
</script>

<style lang="scss" scoped>

.popup-wrap {
  position: fixed !important;
  height: 100vh;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;

  .bg {
    position: fixed;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    opacity: 0.3;
    background: var(--gray-100);
    z-index: 101;
  }
  &.normal{
    .popup-container{

    }
  }
  &.full{
    .popup-container{
      width:100%;
      height:100%;
      max-width: 100%;
    }
  }
  &.bottom{
    align-items: flex-end;
    .popup-container{
      padding-top:60px;
      padding-bottom:82px;
      border-radius: 12px 12px 0 0;
      width:100%;
      height:fit-content;
      max-width: 100%;
    }
  }
  .popup-container {
    padding:30px 20px 53px 20px;
    position:relative;
    box-sizing: border-box;
    width:100%;
    z-index: 103;
    min-width: 100px;
    // width: fit-content;
    height: fit-content;
    max-width: 440px;
    background: #F8F7EF;
  .btn-close{
    position: absolute;
    top: 16px;
    right: 20px;
    z-index: 2;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    font-size: 0;
    cursor: pointer;
  }
  .popup-title {
    // padding:0 20px;
    // display: flex;
    // align-items: flex-end;
    // position:relative;
    // width:100%;
    // height:86px;
    // box-sizing: border-box;
    // color: var(--gray-100);
    // font-size: 22px;
    // font-weight: 600;
    // line-height: normal;
    // letter-spacing: -0.4px;
    span{
      white-space: pre-line;
    }
  }
  .popup-content{
    height: 100%;
    box-sizing: border-box;
  }
  .description {
    font-size: 15px;
    color: #888899;
    white-space: pre-line;
    // padding-bottom: 10px;
  }
  .button-group {
    margin-top:40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

}

@media only screen and (min-width: 640px) {
  //가로값이 640이하에서 적용
  .popup-wrap{
    .popup-container{
      padding:30px 30px 82px 30px;
    }
  }

  .popup-wrap.full{
    .popup-container{
      max-width: 440px;
      height: fit-content;
    }
  }

  .popup-wrap.bottom{
    align-items:center;
    .popup-container{
      padding-top:30px;
      border-radius: 0;
      max-width: 440px;
      height: fit-content;
    }
  }

}
</style>
