<template>
  <!-- <div class="content_wrap">
    <div v-show="$store.state.requested" class="loading">
      <div class="circle"></div>
    </div>
    <sp-header v-if="$route.meta.navigation"></sp-header>
    <router-view :key="$route.path" />
    <sp-modals></sp-modals>
  </div> -->

<!-- ###### -->
<div class="wrap">
    <div v-show="$store.state.requested" class="loading"></div>
    <div v-show="$store.state.requested" class="spinner-wrap">
      <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
    </div>

    <transition name="component-fade" mode="out-in">
      <div v-if="$route.meta.fullScreen" class="fullscreen">
        <router-view :key="$route.path"/>
      </div>
      <div v-else class="normal" :key="$route.path">
        <div v-show="versionNotice" @click="reload" class="version-notice">
          <transition name="slide-down">
            <div class="slide-content">
              🚀 업데이트가 있습니다. 클릭 시 새로고침 됩니다. 🚀
            </div>
          </transition>
        </div>
        <button v-if="!isFolded" style="position:absolute" type="button" class="toggle-nav" @click="toggleNavigation">
          <!-- <ion-icon name="menu-outline" class="icon"></ion-icon> -->

          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="10" width="24" height="2" fill="white"/>
            <rect x="4" y="20" width="24" height="2" fill="white"/>
          </svg>

        </button>
        <navigation v-if="!isFolded" />
        <div class="page-wrap">
          <header class="header" ref="header">
            <button v-if="isFolded" type="button" class="toggle-nav" @click="toggleNavigation">
              <!-- <ion-icon name="menu-outline" class="icon"></ion-icon> -->
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="10" width="24" height="2" fill="#1E1E1E"/>
                <rect x="4" y="20" width="24" height="2" fill="#1E1E1E"/>
              </svg>
            </button>
            <div class="page-path-title">
              <!-- <div ref="menuFullName" class="full-path">{{ menuFullName }}</div> -->
              <div ref="pageTitle" class="title">{{ pageTitle }}</div>
            </div>
            <portal-target class="action-bar" name="action-bar" />
          </header>

          <main class="main" ref="main">
            <router-view @totalPages="totalPages" :key="$route.path + isReload" />
            <!-- <pagination style="flex-basis:content; margin-bottom:40px;" :totalPages="count"/> -->
          </main>
        </div>
      </div>
    </transition>
    <sp-modals></sp-modals>
    <sp-toasts />

    <ErrorMaintenance v-if="maintenanceDetail" :data="maintenanceDetail"></ErrorMaintenance>
  </div>

</template>
<script>
// import aws from 'aws-sdk'
// import Navigation from './Navigation'
// import Pagination from '@/components/common/Pagination'
import SpModals from '@/components/common/SpModals'
import SpToasts from '@/components/common/SpToasts.vue'
// import SpToasts from '@/components/common/SpToasts.vue'

import ErrorMaintenance from '@/components/common/error/ErrorMaintenance.vue'
export default {
  name: 'App',
  data () {
    return {
      fullscreen: true,
      count: 0,
      version: null,
      versionNotice: false,
      versionInterval: null,
    }
  },

  mounted () {
    this.setViewportHeight()
    window.addEventListener('resize', this.setViewportHeight)
    // 배포 업데이트 알람이
    if (location.href.indexOf('local') == -1) {
      this.checkDistributionVersion()
    }
  },
  methods: {
    totalPages (count) {
      this.count = count
    },

    checkDistributionVersion () {
      // 최초 한 번만 실행
      setTimeout(async () => {
        this.version = await this.getDistributionVersion()
      }, 10)

      // 주기적으로 실행
      this.versionInterval = setInterval(async () => {
        const version = await this.getDistributionVersion()

        if (this.version !== version) {
          this.versionNotice = true
          clearInterval(this.versionInterval)
        }
      }, 5 * 60 * 1000) // 5분 (5 * 60 * 1000 밀리초)
    },
    // 현재 배포 버전 획득
    async getDistributionVersion () {
      let version = this.version

      await this.$http.get(
        '/.version',
        { withCredentials: false },
      ).then(res => {
        version = res.data
      }).catch(err => {
        console.error(err)
      })

      return version
    },
    reload () {
      location.reload()
    },
  },
  components: {
    // Navigation,
    // Pagination,
    SpModals,
    SpToasts,
    ErrorMaintenance,
  },
  computed: {
    isFolded () {
      return this.$store.state.isFolded
    },
    isReload () {
      // 로그인 하거나 새로고침했을때 reload 가1증가 하기때문에  0이나 1은 변화로 감지 안되게 하기위하여 일괄적으로 0으로 만듬
      return this.$store.state.user.reload <= 1 ? 0 : this.$store.state.user.reload
    },
    // lnb () {
    // const parentMenuNo = this.$store.getters.pathToMenu(this.$route.path)?.parentMenuNo || 0
    // return this.$store.state.tree[parentMenuNo] || {}
    // },
    pageTitle () {
      return this.$route.meta.pageTitle || this.$store.getters.pathToMenu(this.$route.path)?.menuNm
    },
    menuFullName () {
      return this.$store.getters.pathToMenu(this.$route.path)?.menuFullNm
    },
    isProduction () {
      return process.env.VUE_APP_MODE === 'production'
    },
    maintenanceDetail () {
      return this.$store.state.user.maintenanceDetail
    },
  },
  watch: {
    '$route.meta.fullScreen': {
      deep: true,
      immediate: true,
      handler: function (isFullscreen) {
        this.fullscreen = isFullscreen
      },
    },
  },
  created () {

    const randomToken = this.getCookie('member_token') || null
    this.$store.commit('user/setAccessToken', randomToken) // 최초에 토큰을 스토어에 넣기위한 코드

    if (!this.isProduction) {
      document.title = `(${process.env.VUE_APP_MODE_KR ?? 'DEV'}) ${document.title}`
    }
  },
}
</script>
<style lang="scss" scoped>
  .wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
    min-height: 100%;
    .page-wrap {
      overflow: hidden;
      height: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      background-color: #F8F7EF;
      .header {
        display: flex;
        // justify-content: space-between;
        box-sizing: border-box;
        align-items: center;
        padding: 100px 72px 64px 72px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 130px;
        .toggle-nav {
          // transform: translate(-36px, -52px);
          transform: translate(-72px, -100px);
        }
        .toggle-nav + .page-path-title {
          transform: translateX(-40px);
        }
        .page-path-title {
          // flex-basis: 100%;
          margin-right:10px;
          .full-path {
            color: #BDBDBD;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
          }
          .title {
            color: #000;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -1.28px;
          }
        }
      }
      .main {
        padding:0 72px;
        // background-color: #fffffff1;
        // border-bottom: 1px solid #d0d0d0;
      }
    }

    .component-fade-enter-active,
    .component-fade-leave-active {
      transition:
        opacity .3s linear,
        filter  .3s linear,
      ;
    }
    .component-fade-enter,
    .component-fade-leave-to {
      opacity: .95;
      filter: blur(2px);
    }
    $offset: 187;
    $duration: 1.4s;
    .spinner-wrap {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1001;
    }
    .spinner {
      animation: rotator $duration linear infinite;
    }

    @keyframes rotator {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(270deg); }
    }
    .path {
      stroke-dasharray: $offset;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation:
        dash $duration ease-in-out infinite,
        colors ($duration*4) ease-in-out infinite;
    }
    @keyframes colors {
      0% { stroke: #4285F4; }
      25% { stroke: #DE3E35; }
      50% { stroke: #F7C223; }
      75% { stroke: #1B9A59; }
      100% { stroke: #4285F4; }
    }
    @keyframes dash {
      0% { stroke-dashoffset: $offset; }
      50% {
        stroke-dashoffset: $offset/4;
        transform:rotate(135deg);
      }
      100% {
        stroke-dashoffset: $offset;
        transform:rotate(450deg);
      }
    }

    /deep/ {
      .multiselect {
        display: inline-block;
        box-sizing: border-box;
        margin: 4px 0;
        padding:0  2.5px;
        line-height: 24px;
        min-width: 60px;
        min-height: 24px;
        vertical-align: middle;
        &--disabled {
          background: transparent;
          .multiselect__select {
            background: transparent;
          }
          .multiselect__single {
            background: #f6f6f6;
            color: #000;
            height: 24px;
            padding: 2px 0 0 5px;
          }
          .multiselect__tags {

            background: #f6f6f6;
          }
          .multiselect__placeholder {
            color: #000;
          }
        }
      }

      .multiselect__select {
        right: -6px;
        top: 2px;
        line-height: 24px;
        height: 24px;
        &:before {
          border-color: var(--theme-primary-color) transparent transparent;
          top: 7px;
          border-style: solid;
          border-width: 5px 5px 0;
        }
      }
      .multiselect__tag {
        background-color: var(--theme-primary-color);
        margin: 2.5px;
        vertical-align: top;
        height: 20px;
      }
      .multiselect__tag-icon {
        &:hover {
          background: var(--theme-primary-color);
        }
        &:after {
          color: #fff;
        }
      }
      .multiselect__tags {
        padding: 0;
        min-height: 22px;
        margin: 0;
        border-color: #ddd;
        box-sizing: border-box;
      }
      .multiselect--active {
        .multiselect__tags .multiselect__input {
          // margin-top: 5px;
        }
        .multiselect__tags-wrap {
          overflow: initial;
          height: 22px;
        }
      }
      .multiselect__tags-wrap {
        position: relative;
        // top: 2px;
        overflow: auto;
        height: fit-content;
        display: inline-block;
        width: calc(100% - 24px);
      }
      .multiselect__input {
        width: auto;
        font-size: 14px;
        line-height: 24px;
        text-indent: 0px;
        margin-bottom: 0;
      }
      .multiselect__placeholder {
        font-size: 14px;
        line-height: 24px;
        padding: 0;
        margin: 0;
        text-indent: 5px;
        height: 24px !important;
        overflow: hidden;
        display: block !important;
        text-overflow: ellipsis;
        font-weight: normal;
      }
      .multiselect__option {
        padding: 0 10px;
        height: 24px;
        min-height: 0;
        line-height: 24px;
        font-size: 14px;
      }
      .multiselect__option--highlight::after, .multiselect__option--selected::after {
        height: 24px;
        min-height: 0;
        line-height: 24px;
      }
      .multiselect__content-wrapper{
        min-width: 150px;
        width: calc(100% - 5px);
        padding: 0 2.5px;
        box-sizing: border-box;
        ul {
          padding-inline-start: 0;
          li {
            width: 100%;
            overflow: hidden;
          }
        }
      }
      .multiselect__option--highlight {
        background-color: var(--theme-primary-color);
        &::after {
          background-color: var(--theme-primary-color);
        }
      }
      .multiselect__single {
        font-weight: normal;
        font-size: 14px;
        margin: 1px 0;
        height: 22px;
        line-height: 22px;
        overflow: hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
      }
    }
    /deep/ .ck-editor {
      .ck.ck-labeled-field-view>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label {
        height: 14px;
      }
    }
    .version-notice{
      z-index: 10;
      position: fixed;
      background-color: #2cb6af;
      color: white;
      font-weight: bold;
      width: 100%;
      text-align: center;
      padding: 10px;
      animation: showfadeout 5s normal;
      opacity: 1;
      cursor: pointer;
    }
    @keyframes showfadeout {
      50% {
        transition: transform 3s;
        transform: translateY(100%);
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 600px) {

    // .container {
    //   padding: 0 20px;
    // }

    .wrap {
      .normal {
        display: block;
        min-height: 100%;
        align-content: initial;
        width: 100%;
      }
      .navigation + .page-wrap {
        display: none;
      }
      .page-wrap {
        overflow: initial;
        .header {
          position: sticky;
          background: #F8F7EF;
          top:0;
          z-index: 900;
          flex-basis: initial;
          padding: 6px 7.5px;
          .toggle-nav {
            margin : 0;
            transform: translate(0,0);
          }
          .toggle-nav + .page-path-title {
            transform: translateX(0);
          }
          .page-path-title {
            // flex-basis: 100%;
            margin-right:10px;
            overflow: hidden;
            .full-path {
              padding: 4px 5px;
              color: #BDBDBD;
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .title {
              padding: 0 5px;
              font-weight: 600;
              font-size: 16px;
              line-height: 16px;
            }
          }
          .action-bar {
            text-align: right;
          }
        }
        .main {
          padding:50px 20px 0 20px;
          background-color: #F8F7EF;
          // border-bottom: 1px solid #d0d0d0;
          /deep/ .filter label {
            display: block;
          }
          /deep/ .filter .inputbox>label {
            display: inline-block;
          }
        }
      }
    }

  }

</style>
