<template>
<div>
  <transition-group name="fade">
    <div
    class="toast-message"
    :style="{bottom: `calc(${(i * 70 + toast.bottom) + 50}px)`}"
    v-for="toast, i in toasts"
    :key="`${i}`">
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <circle cx="5" cy="5" r="5" :fill="iconColor(toast.icon)"/>
      </svg>
      <p>{{toast.message}}</p>
      <a
      v-if="toast.button?.url"
      class="action-button"
      :href="toast.button?.url"
      target="_blank">
        {{ toast.button?.name }}
      </a>
    </div>
  </transition-group>
</div>
</template>
<script>

export default {
  name: 'SpToasts',
  components: {
  },
  data () {
    return {

    }
  },
  props: {
  },
  mounted () {
  },
  methods: {

  },
  computed: {
    iconColor () {
      return (icon) => {
        return {
          success: '#32B478',
          error: '#CF2027',
        }?.[icon] || '#32B478'
      }
    },
    toasts () {
      return this.$store.state.toasts.slice().reverse()
    },
  },
  watch: {

  },
}
</script>

<style lang="scss" scoped>

.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.toast-message {
  position: fixed;
  display: flex;
  align-items: center;
  left:50%;
  transform: translateX(-50%);
  // width: calc(100% - 20px);
  min-width: 343px;
  box-sizing: border-box;
  // max-width: 560px;
  padding: 20px;
  // margin-bottom:24px;

  border-radius: 6px;
  background-color: #4F4E47;
  // transform: translateX(-50%);
  z-index: 1000;
  svg {
    margin-right: 10px;
  }
  p {
    color: #FFF;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    margin:0;
  }
  .action-button {
    flex: 0 0 auto;
    margin-left: auto;
    padding-left: 8px;

    color: var(--Gray-Gray-FFF, #FFF);
    text-align: center;
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    text-transform: capitalize;
  }
}
@media screen and (min-width: 600px) {
  .toast-message {
    // width: auto;
    // min-width: 343px;
  }
}
@media only screen and (min-width: 640px) {
  .toast-message {
    // left: 352px;
    // transform: none;
  }
}
</style>
