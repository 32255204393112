<template>
    <span>
      {{ formattedTime }}
    </span>
  </template>

<script>
export default {
  name: 'SpTimer',
  props: {
    countTime: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data () {
    return {
      timeRemaining: this.countTime,
      timerId: null,
      endTime: null,
    }
  },
  computed: {
    // 타이머
    formattedTime () {
      const minutes = Math.floor(this.timeRemaining / 60)
      const seconds = this.timeRemaining % 60
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    },
  },
  mounted () {
    // this.startTimer()
  },
  beforeUnmount () {
    // console.log('파괴')
    clearInterval(this.timerId)
  },
  methods: {
    // 타이머-갱신
    updateTimer () {
      const currentTime = new Date().getTime() // 현재 시간을 밀리초로 가져오기
      const remainingTime = Math.floor((this.endTime - currentTime) / 1000)

      if (remainingTime > 0) {
        this.timeRemaining = remainingTime
      } else {
        console.log('타이머 종료')
        this.timeRemaining = 0
        this.stopTimer()
        this.$emit('end')
      }
    },

    // 타이머-시작
    startTimer () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      const currentTime = new Date().getTime() // 현재 시간을 밀리초로 가져오기
      this.endTime = currentTime + this.timeRemaining * 1000 // 종료 시간 계산
      this.timerId = setInterval(this.updateTimer, 500)
    },

    reStartTimer () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(this.updateTimer, 500)
    },

    // 타이머-정지
    stopTimer () {
      if (this.timerId) {
        clearInterval(this.timerId)
        this.timerId = null
      }
    },
    // 타이머-초기화
    resetTimer () {
      this.timeRemaining = this.countTime
    },
  },
}
</script>
